import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"

const imgflow = "../../images/guides/bugtrackerflow.png"


const BugtrackerPage = () => {
	return (

	<Layout activePath='guides/bugtracker' pageTitle="Bugtracker" description="Standard issue tracker life cycle" meta={[{name: `robots`, content: `noindex`}]}>
		<div className="margin-content">
			<h1>Bugtracker</h1>
			<h3>Flow / Lifecycle</h3>
			<StaticImage layout="fullWidth" src={imgflow} alt={"Flow/Lifecycle"} />
			<div>
				<div tag="color: #40A0FF; display: inline-block; margin-left: 70px;"><strong>Blue</strong> = Reporter</div>
				<div tag="color: #8F8F40; display: inline-block; margin-left: 70px;"><strong>Yellow</strong> = Developer/Development Team</div>
			</div>
			<ul>
				<li>New - Created by the reporter</li>
				<li>Assigned - To indicate that issue has been noted or if item has not been resolved</li>
				<li>Feedback - Information is being requested from the reporter.</li>
				<li>Resolved - A solution has been achieved (e.g. fixed, not a bug, etc)
						<ul>
							<li><strong>Describe the solution</strong>.  Answer WHY the problem was there, and/or WHAT was done to address it.</li>
							<li>Be sure to test the solution.</li>
							<li>Make sure that the solution is available in the actual environment or has been "committed" to the version control system.</li>
						</ul>
				</li>
				<li>Closed - Set by the REPORTER to confirm that the problem has been addressed.</li>
			</ul>
			<h3>Reporting</h3>
			<ul>
				<li><b>Summary</b> - Brief phrase to describe the problem.
					<ul>
						<li>Keep it short.  Use the description field to expound.</li>
						<li>Do not use generic phrases. (e.g. input error, wrong format, etc)</li>
						<li>Be concise.  It does not have to be a complete sentence.</li>
					</ul>
				</li>
				<li><b>Description</b> - Details of the problem.
					<ul>
						<li>Get straight to the point</li>
						<li>Use short sentences and new lines (ala-bullets)</li>
						<li>Use labeled screenshots to make things easier (refer reader to them)</li>
						<li>Be sure to answer WHAT, HOW and WHEN.</li>
						<li>Include observations/suggestions (if any) as a separate paragraph or note.</li>
						<li>Months or years from now, you should still be able to understand what you wrote here.</li>
					</ul>
				</li>
			</ul>
		</div>
	</Layout>
	)
}

export default BugtrackerPage
